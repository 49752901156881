import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import type { NextPage } from "next";
import { track } from "@amplitude/analytics-browser";
import Hero from "@components/Hero";
import Footer from "@components/Footer";
import LatesConnections from "@components/LatestConnections";
import ConnectionHistoryTable from "@components/ConnectionHistoryTable";
import { ConnectionEvent } from "@/types";
import format from "date-fns/format";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import {
  useConnectionEventsLazyQuery,
  useConnectionEventsQuery,
  Network,
  ConnectionEvent as APIConnectionEvent,
  useConnectionSummaryQuery,
} from "@/graphql";
import EnvironmentContext from "@contexts/EnvironmentContext";

type DataSummary = {
  totalConnections: number;
  totalUsers: number;
  newConnections: number;
  newUsers: number;
  totalProjects: number;
};

const Home: NextPage = () => {
  const environment = React.useContext(EnvironmentContext);
  const {
    data,
    refetch,
    loading: loadingInitialEvents,
  } = useConnectionEventsQuery({
    context: { environment },
  });

  const {
    data: connectionSummaryData,
    loading: loadingSummary,
    refetch: refetchDataSummary,
  } = useConnectionSummaryQuery({
    variables: { namespace: "CyberConnect" },
    context: { environment },
  });

  const [fetchConnectionEvents, { loading: isLoadingMore }] =
    useConnectionEventsLazyQuery({
      fetchPolicy: "no-cache",
      context: { environment },
    });

  const [connections, setConnections] = React.useState<ConnectionEvent[]>([]);
  const [hasMore, setHasMore] = React.useState<boolean>(false);
  const [nextCursor, setNextCursor] = React.useState<string | null>(null);
  const [dataSummary, setDataSummary] =
    React.useState<DataSummary | null>(null);

  React.useEffect(() => {
    track("Page View", { url: "/", name: "Homepage" });
  }, []);

  React.useEffect(() => {
    refetch();
    refetchDataSummary();
  }, [environment]);

  React.useEffect(() => {
    if (!loadingSummary && connectionSummaryData?.connectionSummary) {
      const { connectionSummary } = connectionSummaryData;
      setDataSummary({
        totalConnections: connectionSummary.connectionCount,
        totalUsers: connectionSummary.userCount,
        newConnections: connectionSummary.connectionDelta,
        newUsers: connectionSummary.userDelta,
        totalProjects: connectionSummary.namespaceCount,
      });
    }
  }, [connectionSummaryData, loadingSummary]);

  const handleResponse = (responseData: any, isConcated: boolean = false) => {
    setHasMore(responseData.connectionEvents.pageInfo.hasNextPage);
    setNextCursor(responseData.connectionEvents.pageInfo.endCursor);

    const connectionlist = (responseData as any).connectionEvents.list
      .map((c: APIConnectionEvent) => ({
        hash: c.hash,
        type: c.type.toLowerCase() as any,
        fromAddress: c.fromAddr,
        toAddress: c.toAddr,
        time: formatDistanceToNowStrict(new Date(c.createdAt), {
          addSuffix: true,
        }),
        project: {
          name: c.namespace,
          namespace: c.namespace,
          profileURL: "",
          network: c.network as any,
        },
        link: `/connection/${c.hash}`,
        namespace: c.namespace,
        network: c.network as any,
      }))
      .filter((c1: any) => !connections.some((c2) => c1.hash === c2.hash));

    if (isConcated) {
      setConnections([...connections, ...connectionlist]);
    } else {
      setConnections(connectionlist);
    }
  };

  React.useEffect(() => {
    if (data) {
      handleResponse(data);
    }
  }, [data]);

  const loadMoreConnections = async () => {
    if (hasMore && nextCursor !== null) {
      const response = await fetchConnectionEvents({
        variables: {
          network: Network.Eth,
          after: nextCursor,
        },
        context: { environment },
      });

      handleResponse(response.data, true);
    }
  };

  return (
    <Box sx={{ px: { xs: 2.5, md: 13.5 } }}>
      <Box mt={4}>
        <Hero dataSummary={dataSummary} loading={loadingSummary} />
      </Box>
      <Box mt={{ xs: 8, md: 10 }}>
        <Typography fontSize={28} fontWeight={700} color="text.primary" mb={3}>
          Latest Connections
        </Typography>
        <ConnectionHistoryTable
          initialLoading={loadingInitialEvents}
          hasMore={hasMore}
          loadMore={loadMoreConnections}
          connections={connections}
        />
      </Box>
      <Box mt={10} mb={{ xs: 15, md: 30 }}>
        <Footer />
      </Box>
    </Box>
  );
};

export default Home;
