import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ConnectionList from "@components/ConnectionList";
import SearchInput from "@components/SearchInput";
import Button from "@mui/material/Button";
import Icon from "@components/Icon";
import NumberCard from "@components/NumberCard";
import { ConnectionEvent } from "@/types";
import { useRouter } from "next/router";

const caption = "Portable, self-sovereign, and blockchain-agnostic.";
const description = "Explore CyberConnect Social Graph";

export type HeroProps = {
  loading: boolean;
  dataSummary: {
    totalUsers: number;
    totalConnections: number;
    newUsers: number;
    newConnections: number;
    totalProjects: number;
  } | null;
};

const searchPage = {
  "0": "/address",
  "1": "/connection",
  "2": "/namespace",
} as const;

const Hero = ({ dataSummary, loading }: HeroProps) => {
  // Search type
  // Address: "0"
  // Connection Hash: "1"
  // Namespace: "2"
  const [searchType, setSearchType] = React.useState("0");
  const [inputValue, setInputValue] = React.useState("");

  const router = useRouter();

  const handleSearch = () => {
    if (inputValue.length > 0) {
      router.push(searchPage[searchType as "0" | "1" | "2"] + "/" + inputValue);
    }
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      setInputValue(event.target.value);
    }
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (inputValue.length > 0 && event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Stack>
      <Typography
        color="text.primary"
        fontSize={28}
        fontWeight={700}
        textAlign="center"
        mb={5}
      >
        {description}
      </Typography>
      <Box>
        <SearchInput
          handleSearch={handleSearch}
          selectSearchType={(value) => setSearchType(value)}
          onChange={handleInput}
          onKeyPress={handleEnter}
        />
      </Box>
      <Grid
        container
        columnSpacing={1.5}
        mt={4}
        data-testid="data-summary"
        rowGap={{ xs: 0.5, md: 0 }}
      >
        <Grid item xs={6} md={2.4}>
          <NumberCard
            title="Total users"
            value={dataSummary ? dataSummary.totalUsers : 0}
            icon="user-group"
            loading={loading}
          />
        </Grid>
        <Grid item xs={6} md={2.4}>
          <NumberCard
            title="Total connections"
            value={dataSummary ? dataSummary.totalConnections : 0}
            icon="connection"
            loading={loading}
          />
        </Grid>
        <Grid item xs={6} md={2.4}>
          <NumberCard
            title="Namespaces"
            value={dataSummary ? dataSummary.totalProjects : 0}
            icon="integration"
            loading={loading}
          />
        </Grid>
        <Grid item xs={6} md={2.4}>
          <NumberCard
            title="New connections in 24h"
            value={dataSummary ? dataSummary.newConnections : 0}
            icon="new-connection"
            loading={loading}
          />
        </Grid>
        <Grid item xs={12} md={2.4}>
          <NumberCard
            title="New users in 24h"
            value={dataSummary ? dataSummary.newUsers : 0}
            icon="new-user"
            loading={loading}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Hero;
