import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import addCommaToNum from "@utils/addCommaToNum";
import Icon from "@components/Icon";
import type { IconName } from "@/types";
import Skeleton from "@mui/material/Skeleton";

export interface NumberCardProps {
  title: string;
  value: number;
  icon: IconName;
  loading?: boolean;
}

const NumberCard = ({ title, value, icon, loading }: NumberCardProps) => {
  return (
    <Box
      p={{ xs: 1.5, md: 3 }}
      bgcolor="#fff"
      borderRadius="12px"
      data-testid="number-card"
      border="1px solid #D4D4D4"
      boxSizing="border-box"
      height="100%"
    >
      <Icon name={icon} size="large" />
      {loading ? (
        <Skeleton
          animation="wave"
          height={42}
          width="100%"
          data-testid="skeleton"
        />
      ) : (
        <Typography fontSize={{ xs: 20, md: 28 }} color="text.primary">
          {addCommaToNum(value)}
        </Typography>
      )}
      <Typography fontSize={15} color="text.primary" sx={{ opacity: 0.8 }}>
        {title}
      </Typography>
    </Box>
  );
};

export default NumberCard;
