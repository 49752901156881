import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowSpacingParams,
  GridColumnHeaderParams,
  DataGridProps,
} from "@mui/x-data-grid";
import Icon from "@components/Icon";
import { styled } from "@mui/material/styles";
import type { ConnectionEvent, ConnectionType, IconName } from "@/types";
import Link from "next/link";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export interface ConnectionHistoryTableProps extends Partial<DataGridProps> {
  connections?: ConnectionEvent[];
  hasMore: boolean;
  loadMore: () => void;
  initialLoading: boolean;
}

const StyledLink = styled("a")({
  color: "#01218D",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
  textOverflow: "ellipsis",
  overflow: "hidden",
});

const action: Record<ConnectionType, string> = {
  follow: "followed",
  unfollow: "unfollowed",
  like: "liked",
  report: "reported",
  watch: "watched",
  vote: "voted for",
};

const loadingColumns: GridColDef[] = [
  {
    field: "type",
    headerName: "",
    width: 80,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <Stack alignItems="center" flex={1}>
        <Skeleton
          data-testid="skeleton"
          animation="wave"
          height={48}
          width={30}
        />
      </Stack>
    ),
  },
  {
    field: "hash",
    headerName: "Connection Hash",
    sortable: false,
    width: 180,
    renderCell: (params: GridRenderCellParams) => (
      <Stack alignItems="center" flex={1}>
        <Skeleton
          data-testid="skeleton"
          animation="wave"
          width="100%"
          height={28}
        />
      </Stack>
    ),
  },
  {
    field: "fromAddress",
    headerName: "From",
    sortable: false,
    width: 180,
    renderCell: (params: GridRenderCellParams) => (
      <Stack alignItems="center" flex={1}>
        <Skeleton
          data-testid="skeleton"
          animation="wave"
          width="100%"
          height={28}
        />
      </Stack>
    ),
  },
  {
    field: "x",
    sortable: false,
    width: 120,
    valueGetter: (params) => {
      return action[params.row.type as ConnectionType];
    },
    renderHeader: (params: GridColumnHeaderParams) => (
      <Stack alignItems="center" flex={1}>
        Operation
      </Stack>
    ),
    renderCell: (params: GridRenderCellParams) => (
      <Stack alignItems="center" flex={1}>
        <Skeleton
          data-testid="skeleton"
          animation="wave"
          width="100%"
          height={28}
        />
      </Stack>
    ),
  },
  {
    field: "toAddress",
    headerName: "To",
    sortable: false,
    width: 180,
    renderCell: (params: GridRenderCellParams) => (
      <Stack alignItems="center" flex={1}>
        <Skeleton
          data-testid="skeleton"
          animation="wave"
          width="100%"
          height={28}
        />
      </Stack>
    ),
  },
  {
    field: "network",
    headerName: "Network",
    sortable: false,
    width: 120,
    renderHeader: (params: GridColumnHeaderParams) => (
      <Stack alignItems="center" flex={1}>
        Network
      </Stack>
    ),
    renderCell: (params: GridRenderCellParams) => (
      <Stack alignItems="center" flex={1}>
        <Skeleton
          data-testid="skeleton"
          animation="wave"
          width="100%"
          height={28}
        />
      </Stack>
    ),
  },
  {
    field: "namespace",
    headerName: "Namespace",
    sortable: false,
    flex: 1,
    width: 120,
    renderHeader: (params: GridColumnHeaderParams) => (
      <Stack alignItems="center" flex={1}>
        Namespace
      </Stack>
    ),
    renderCell: (params: GridRenderCellParams) => (
      <Stack alignItems="center" flex={1}>
        <Skeleton
          data-testid="skeleton"
          animation="wave"
          width="100%"
          height={28}
        />
      </Stack>
    ),
  },
  {
    field: "time",
    headerName: "Timestamp",
    sortable: false,
    flex: 1,
    width: 120,
    renderHeader: (params: GridColumnHeaderParams) => (
      <Stack alignItems="center" flex={1}>
        Timestamp
      </Stack>
    ),
    renderCell: (params: GridRenderCellParams) => (
      <Stack alignItems="center" flex={1}>
        <Skeleton
          data-testid="skeleton"
          animation="wave"
          width="100%"
          height={28}
        />
      </Stack>
    ),
  },
];

const columns: GridColDef[] = [
  {
    field: "type",
    headerName: "",
    width: 80,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <Stack alignItems="center" flex={1}>
        <Icon name={params.value as IconName} />
      </Stack>
    ),
  },
  {
    field: "hash",
    headerName: "Connection Hash",
    sortable: false,
    width: 180,
    renderCell: (params: GridRenderCellParams<string>) => (
      <Link href={`/connection/${params.value}`}>
        <StyledLink>{params.value || ""}</StyledLink>
      </Link>
    ),
  },
  {
    field: "fromAddress",
    headerName: "From",
    sortable: false,
    width: 180,
    renderCell: (params: GridRenderCellParams<string>) => (
      <Link href={`/address/${params.value}`}>
        <StyledLink>{params.value || ""}</StyledLink>
      </Link>
    ),
  },
  {
    field: "x",
    sortable: false,
    width: 120,
    valueGetter: (params) => {
      return action[params.row.type as ConnectionType];
    },
    renderHeader: (params: GridColumnHeaderParams) => (
      <Stack alignItems="center" flex={1}>
        Operation
      </Stack>
    ),
    renderCell: (params: GridRenderCellParams<IconName>) => (
      <Stack alignItems="center" flex={1}>
        {params.value}
      </Stack>
    ),
  },
  {
    field: "toAddress",
    headerName: "To",
    sortable: false,
    width: 180,
    renderCell: (params: GridRenderCellParams<string>) => (
      <Link href={`/address/${params.value}`}>
        <StyledLink>{params.value || ""}</StyledLink>
      </Link>
    ),
  },
  {
    field: "network",
    headerName: "Network",
    sortable: false,
    width: 120,
    renderHeader: (params: GridColumnHeaderParams) => (
      <Stack alignItems="center" flex={1}>
        Network
      </Stack>
    ),
    renderCell: (params: GridRenderCellParams<IconName>) => (
      <Stack alignItems="center" flex={1}>
        {params.value}
      </Stack>
    ),
  },
  {
    field: "namespace",
    headerName: "Namespace",
    sortable: false,
    width: 120,
    minWidth: 120,
    renderHeader: (params: GridColumnHeaderParams) => (
      <Stack alignItems="center" flex={1}>
        Namespace
      </Stack>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <Stack alignItems="center" flex={1}>
        <Link href={`/namespace/${params.value}`}>
          <StyledLink>{params.value}</StyledLink>
        </Link>
      </Stack>
    ),
  },
  {
    field: "time",
    headerName: "Timestamp",
    sortable: false,
    width: 120,
    minWidth: 120,
    renderHeader: (params: GridColumnHeaderParams) => (
      <Stack alignItems="center" flex={1}>
        Timestamp
      </Stack>
    ),
    renderCell: (params: GridRenderCellParams<string>) => (
      <Stack alignItems="center" flex={1}>
        {params.value}
      </Stack>
    ),
  },
];

type TableFooterProps = {
  goPrev: () => void;
  goNext: () => void;
  buttonText: string;
  isPrevDisabled: boolean;
  isNextDisabled: boolean;
};

const TableFooter = ({
  goPrev,
  goNext,
  buttonText,
  isPrevDisabled,
  isNextDisabled,
}: TableFooterProps) => {
  return (
    <Stack direction="row" justifyContent="center" py={2} mt={1}>
      <IconButton onClick={goPrev} disabled={isPrevDisabled}>
        &lt;
      </IconButton>
      <Typography
        align="center"
        sx={{ display: "flex", alignItems: "center", margin: "0 10px" }}
      >
        {buttonText}
      </Typography>
      <IconButton onClick={goNext} disabled={isNextDisabled}>
        &gt;
      </IconButton>
    </Stack>
  );
};

// Generate 10 loading rows
const generateLoadingRows = () => {
  return new Array(10).fill(0).map((_, idx) => ({
    type: "",
    hash: `${idx}`,
    fromAddress: "",
    toAddress: "",
    network: "",
    namespace: "",
    time: "",
  }));
};

const ConnectionHistoryTable = ({
  connections = [],
  hasMore = true,
  loadMore,
  initialLoading = false,
  ...rest
}: ConnectionHistoryTableProps) => {
  const theme = useTheme();

  const breakpointMD = useMediaQuery(theme.breakpoints.up("md"));
  const [page, setPage] = React.useState<number>(0);

  const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 4,
      bottom: params.isLastVisible ? 0 : 4,
    };
  }, []);

  const goPrev = async () => {
    setPage(page > 0 ? page - 1 : 0);
  };

  const goNext = async () => {
    await loadMore();
    setPage(page + 1);
  };

  const isLastPage = () => {
    return Math.floor(connections.length / 20) === page;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        page={page}
        sx={{
          borderRadius: "16px",
          px: 2,
          pb: 3,
          bgcolor: "rgba(221, 222, 229, 0.22)",
          border: "none",
          ".MuiDataGrid-row": {
            bgcolor: "#fff",
            borderRadius: "12px",
            "&:hover": { bgcolor: "#fff" },
          },
          ".MuiDataGrid-columnHeaderTitleContainerContent": { flex: 1 },
          ".MuiDataGrid-columnHeader": { px: 1.5 },
          ".MuiDataGrid-columnHeaders": { border: "none" },
          ".MuiDataGrid-cell": {
            border: "none",
            px: 1.5,
            "&:focus": { outline: "none" },
          },
        }}
        components={{
          ColumnResizeIcon: () => null,
          Footer: TableFooter,
          NoRowsOverlay: () => (
            <Stack justifyContent="center" height="100%" alignItems="center">
              <Typography>No Connections</Typography>
            </Stack>
          ),
        }}
        componentsProps={{
          footer: {
            goPrev,
            goNext,
            buttonText: page + 1,
            isPrevDisabled: page === 0,
            isNextDisabled: isLastPage() && !hasMore,
          },
        }}
        rows={initialLoading ? generateLoadingRows() : connections}
        rowHeight={80}
        autoHeight={true}
        columns={initialLoading ? loadingColumns : columns}
        disableSelectionOnClick={true}
        disableColumnMenu={true}
        getRowId={(row: any) => row.hash}
        getRowSpacing={getRowSpacing}
        density={breakpointMD ? "standard" : "compact"}
        pageSize={20}
        rowsPerPageOptions={[20]}
        pagination
        {...rest}
      />
    </Box>
  );
};

export default ConnectionHistoryTable;
