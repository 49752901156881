import React from "react";
import Box from "@mui/material/Box";
import {
  default as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Icon from "@components/Icon";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export type TextInputProps = Partial<MuiTextFieldProps> & {
  handleSearch: () => void;
  selectSearchType: (searchType: string) => void;
};

const StyledInput = styled(MuiTextField)(({ theme }) => ({
  flex: 1,
  ".MuiFilledInput-root": {
    borderRadius: "40px",
    columnGap: theme.spacing(0),
    [theme.breakpoints.up("md")]: {
      columnGap: theme.spacing(2),
    },
    padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    backgroundColor: "rgba(221, 222, 229, 0.4)",
    height: 64,
    ".MuiFilledInput-input": {
      height: 64,
      boxSizing: "border-box",
      "&::placeholder": {
        color: theme.palette.text.primary,
        fontSize: 17,
        fontWeight: 450,
        opacity: 0.6,
      },
    },
  },
  ".MuiFormHelperText-root": {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1),
    fontSize: 15,
    color: theme.palette.text.primary,
    opacity: 0.6,
  },
}));

const SearchTypeSelect = ({
  handleSelect,
}: {
  handleSelect: (type: string) => void;
}) => {
  const [type, setType] = React.useState("0");

  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
    handleSelect(event.target.value as string);
  };

  return (
    <Box sx={{ minWidth: 140 }}>
      <FormControl fullWidth hiddenLabel={true}>
        <Select
          variant="outlined"
          value={type}
          onChange={handleChange}
          sx={{
            height: 40,
            width: { xs: 120, md: 160 },
            borderRadius: "40px",
            justifyContent: "space-around",
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #222223",
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: "1px solid #C1CDD3",
            },
          }}
          MenuProps={{
            MenuListProps: { sx: { py: 2, width: 164 } },
            sx: {
              ".MuiMenu-paper": {
                boxShadow: "0px 2px 8px rgba(34, 34, 35, 0.08)",
                borderRadius: "16px",
                border: "1px solid #D7DDE0",
              },
            },
          }}
        >
          <MenuItem
            sx={{
              "&.Mui-selected": {
                background: "none",
                "&:hover": { background: "none" },
              },
            }}
            value={"0"}
          >
            Address
          </MenuItem>
          <MenuItem
            sx={{
              "&.Mui-selected": {
                background: "none",
                "&:hover": { background: "none" },
              },
            }}
            value={"1"}
          >
            Connection
          </MenuItem>
          <MenuItem
            sx={{
              "&.Mui-selected": {
                background: "none",
                "&:hover": { background: "none" },
              },
            }}
            value={"2"}
          >
            Namespace
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

const SearchInput = ({
  handleSearch,
  selectSearchType,
  ...rest
}: TextInputProps) => {
  const theme = useTheme();
  const breakpointMD = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Stack direction={{ xs: "column", md: "row" }} columnGap={1}>
      <StyledInput
        {...rest}
        variant="filled"
        placeholder={
          breakpointMD
            ? "Search for addresses, connections, and namespaces..."
            : undefined
        }
        InputProps={{
          startAdornment: (
            <Stack
              direction="row"
              alignItems="center"
              height="100%"
              columnGap={2}
            >
              <SearchTypeSelect handleSelect={selectSearchType} />
            </Stack>
          ),
          disableUnderline: true,
          hiddenLabel: true,
          endAdornment: breakpointMD ? (
            <Button
              variant="contained"
              sx={{ borderRadius: "40px", width: 122, height: 48 }}
              onClick={handleSearch}
            >
              Search
            </Button>
          ) : null,
        }}
        inputProps={{
          "data-testid": "search-input",
        }}
      />
    </Stack>
  );
};

export default SearchInput;
