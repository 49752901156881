const addCommaToNum = (num: number): string => {
  const strArr: string[] = String(num).split("");

  for (let i = strArr.length - 3; i > 0; i -= 3) {
    strArr.splice(i, 0, ",");
  }
  const str: string = strArr.join("");
  return str;
};

export default addCommaToNum;
